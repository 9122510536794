export default class HomeIntroTextAnimation {

  constructor(el) {
    this.$el = el;
    this.$links = this.$el.querySelectorAll('a');
    this.initAnimation();
  }

  initAnimation() {
    ScrollTrigger.batch(this.$links, {
      // markers: true,
      start: "top 85%",
      once: true,
      onEnter: batch => {
        batch.forEach(link => {
          link.classList.add('isAnimated');
        });
      },
    })
  }
}
