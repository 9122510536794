export default class ToTopButton {

  constructor() {

    this.$button = document.getElementById('totop'); // Get the button
    this.duration = 0.3; // Animation duration
    this.stopAt = document.getElementById('footer'); // Stop at this element
    this.pinOffsetY = 32; // Offset from bottom of viewport
    this.scrollTrigger = null; // ScrollTrigger instance

    // Cancel if no button
    if (!this.$button) return;

    // Scroll to top on click
    this.onClick();

    // Reveal button after 2 viewport height
    this.initScrollTrigger(0);

  }


  // Scroll to top when clicking on the button
  onClick() {
    this.$button.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
  }


  // Recalculate all values
  initScrollTrigger(horizontalGalleryHeight) {

    let stopAtTop = this.stopAt.getBoundingClientRect().top + window.scrollY; // Get top position of stopAt
    let stopAtTopHeight = this.stopAt.getBoundingClientRect().height; // get height of stopAtTop

    // Get height of document
    let body = document.body;
    let html = document.documentElement;
    let documentHeight = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Calculate the height of 2 viewports
    let buttonPositionTop = window.innerHeight * 2;
    // console.log(documentHeight, buttonPositionTop + stopAtTopHeight);

    // Already initialized and is needed -> Kill and redo
    if (this.scrollTrigger && documentHeight > buttonPositionTop + stopAtTopHeight) {
      // console.log("initScrollTrigger -> Refresh");
      this.$button.style.display = "block";
      this.scrollTrigger.kill()
      this.createScrollTrigger(horizontalGalleryHeight);
    }
    // Is NOT initialized but needed -> Create
    else if (!this.scrollTrigger && documentHeight > buttonPositionTop + stopAtTopHeight) {
      // console.log("initScrollTrigger -> Create");
      this.$button.style.display = "block";
      this.createScrollTrigger(horizontalGalleryHeight);
    }
    // Is initialized but NOT needed -> Disable
    else if (this.scrollTrigger && documentHeight > buttonPositionTop + stopAtTopHeight) {
      // console.log("initScrollTrigger -> Disable");
      this.scrollTrigger.disable();
      this.$button.style.display = "none";
    }
    // Is NOT initialized and NOT needed -> Do nothing
    else {
      // console.log("initScrollTrigger -> Do nothing");
      this.$button.style.display = "none";
    }

  }


  // call function "initScrollTrigger" after all images have been loaded
  refreshToTopButton() {

    let that = this;

    let newImages = document.querySelectorAll("img"); // Adjust the selector as needed
    newImages.forEach(function (newImage) {
      newImage.addEventListener("load", function () {
        // Check if all images are now loaded
        let allImages = document.querySelectorAll("img");
        let allLoaded = Array.from(allImages).every(function (image) {
          return image.complete;
        });

        if (allLoaded) {
          // console.log("All images loaded");
          that.initScrollTrigger(0);
        }
      });
    });
  }


  // Reveal button
  createScrollTrigger(horizontalGalleryHeight) {
    // make horizontalGallerHeight 0 if the value is undefined
    horizontalGalleryHeight = horizontalGalleryHeight || 0;
    // console.log("createScrollTrigger", horizontalGalleryHeight);

    this.scrollTrigger = ScrollTrigger.create({
      // markers: true,
      pin: true,
      pinSpacing: false,
      trigger: this.$button,
      start: () => `bottom+=${this.pinOffsetY}px bottom`,
      endTrigger: this.stopAt,
      end: () => `top bottom-=${0}`,
      onEnter: () => {
        gsap.to(this.$button, {
          autoAlpha: 1,
          y: "-=8",
          duration: this.duration,
          ease: "power4.in"
        });
        ScrollTrigger.update();
      },
      onLeaveBack: () => {
        gsap.to(this.$button, {
          autoAlpha: 0,
          y: "-=24",
          duration: this.duration,
          ease: "power4.out"
        });
      },
    });

  }



}
