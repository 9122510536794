export default class HomeSlider {

  constructor(el) {
    this.$el = el;
    this.slider = this.initSlider();
  }


  // Init Swiper
  initSlider() {

    let slider = new Swiper(this.$el, {
      spaceBetween: 0,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 600,
      pagination: {
        el: this.$el.querySelector('.swiper-pagination'),
        clickable: true
      },
    });

    return slider;

  }

}
