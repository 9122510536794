export default class DefaultPageAnimation {

  constructor(el) {
    this.$el = el;
    this.$sections = this.$el.querySelectorAll('section');
    this.initAnimation();
  }

  // Animation
  initAnimation() {

    // GSAP default easing
    gsap.defaults({
      ease: "power4.out",
      duration: 0.9
    });

    ScrollTrigger.batch(this.$sections, {
      // markers: true,
      start: "top 85%",
      once: true,
      onEnter: batch => {
        gsap.to(batch, {
            opacity: 1,
            // y: 0,
            stagger: 0.3,
          }),
          batch.forEach(section => {
            section.classList.add('isAnimated');
          });
      }
    })
  }
}
