// SWUP
import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';

// Base
import Navigation from './components/navigation'
import ToTopButton from './components/toTopButton';

import HomeSlider from './components/homeSlider'
import GalleryFilter from './components/galleryFilter'
import GalleryAnimation from './components/galleryAnimation'
import ImageListAnimation from './components/imageListAnimation';
import DefaultPageAnimation from './components/defaultPageAnimation';
import HomeIntroTextAnimation from './components/homeIntroTextAnimation';


// Main Navigation
const $navigation = document.getElementById('navigation');
if ($navigation) new Navigation($navigation);

// To Top Button
const toTopButton = new ToTopButton();


// SWUP JS
const swup = new Swup({
  plugins: [
    new SwupPreloadPlugin(),
    new SwupBodyClassPlugin(),
    new SwupHeadPlugin({
      persistAssets: true,
    })
  ]
});


// Init modules if needed
function init() {

  // Refresh to top button
  toTopButton.refreshToTopButton();

  // Init Home Intro Text Animation
  const $homeIntroHeadline = document.querySelector('.home-intro__headline');
  if ($homeIntroHeadline) {
    new HomeIntroTextAnimation($homeIntroHeadline);
  }

  // Init default page animation
  const $defaultPage = document.getElementsByTagName('main')[0];
  if ($defaultPage) {
    new DefaultPageAnimation($defaultPage);
  }

  // Init parallax images
  const parallaxImages = document.querySelectorAll('.parallax img');
  parallaxImages.forEach((el) => {
    new Ukiyo(el, {
      scale: 1.2,
      speed: 1.4, // 1~2 is recommended
      willChange: true, // This may not be valid in all cases
    });
  });

  // Gallery filter
  const $gallery = document.querySelector('.gallery-section');
  if ($gallery) {
    // new GalleryAnimation($gallery);
    new GalleryFilter($gallery);
  }

  // Image list animation
  const $imageList = document.querySelector('.images-section');
  if ($imageList) {
    new ImageListAnimation($imageList);
  }

  // Intro slider
  const $homeSlider = document.querySelector('.home-intro .swiper');
  if ($homeSlider) {
    new HomeSlider($homeSlider);
  }

}

// Highlight active navigation link
function swupActiveLinks() {

  // Deactivate all links
  let allActiveLinks = document.querySelectorAll('nav#navigation > ul li a.active');
  for (const link of allActiveLinks) {
    link.classList.remove('active');
    link.ariaCurrent = false;
  }

  // Deactivate all toggles
  let allActiveToggles = document.querySelectorAll('.toggle.active');
  for (const toggle of allActiveToggles) {
    toggle.classList.remove('active');
  }

  // Find current link
  let currentPath = window.location.pathname;
  let links = document.querySelectorAll('nav#navigation > ul li a');
  let linkFound = false;
  for (const link of links) {

    let linkPath = (new URL(link.href)).pathname;

    // Link found
    if (linkPath == currentPath) {

      // Activate current link
      link.classList.add('active');
      link.ariaCurrent = 'page';
      linkFound = true;

      // highlight current toggle
      let insideMenu = link.closest('.menu') ? true : false;
      if (insideMenu) {
        let parentPage = link.closest('.nav-list-item');
        let currentToggle = parentPage.querySelector('.toggle');
        currentToggle.classList.add('active');
      }
    }
  }

  // Highlight menu item of a link that is not in the menu
  if (!linkFound) {
    // If the body has a "reference" class, activate the reference menu link
    if (document.querySelector('body.reference')) {
      let referencesLink = document.querySelector('nav#navigation > ul li[data-parent="references"] > a');
      referencesLink.classList.add('active');
      referencesLink.ariaCurrent = 'page';
    }
    // If the body has a "reference" class, activate the reference menu link
    if (document.querySelector('body.job')) {
      let jobsLink = document.querySelector('nav#navigation > ul li[data-parent="jobs"] > a');
      jobsLink.classList.add('active');
      jobsLink.ariaCurrent = 'page';
    }
  }
}

// run once when page loads
if (document.readyState === 'complete') {
  init();
  swupActiveLinks();
} else {
  document.addEventListener('DOMContentLoaded', init);
}

// run after every additional navigation by swup
swup.on('contentReplaced', () => {
  init();
  swupActiveLinks();

  // Use an effective way to scroll to the top of the page with an animation but fast
  // console.log("scroll to top");
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, 300);
});


function unload() {
  if (document.querySelector('body.home')) {
    console.log("unload home: ");
  }
}

swup.on('willReplaceContent', unload);
