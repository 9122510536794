export default class DesktopNavigation {

  constructor(el) {

    this.$header = document.getElementById('header');
    this.$nav = document.getElementById('navigation');
    this.stickyNavigation();

  }

  stickyNavigation() {

    // Make #navigation sticky with gsap scrolltrigger
    gsap.to(this.$nav, {
      scrollTrigger: {
        trigger: this.$header,
        start: () => `top+=${this.$header.offsetHeight - this.$nav.offsetHeight - 16} top`,
        // markers: true,
        pin: true,
        pinSpacing: false,
        anticipatePin: true,
        endTrigger: "#footer",
        end: "bottom bottom",
      },
    });

  }

}
