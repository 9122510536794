// import ViewportResized from '../helper/viewportResized';
import MobileNavigation from './mobileNavigation';
import DesktopNavigation from './desktopNavigation'

export default class Navigation {

  constructor(el) {
    this.$el = el;
    this.initNavigation();
  }

  initNavigation() {
    let mm = gsap.matchMedia();

    mm.add("(max-width: 1199px)", () => {
      new MobileNavigation(this.$el);
    });

    mm.add("(min-width: 1200px)", () => {
      new DesktopNavigation(this.$el);
    });
  }

}
