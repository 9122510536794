export default class ImageListAnimation {

  constructor(el) {
    this.$el = el;
    this.$images = this.$el.querySelectorAll('.images-section__item');
    this.initAnimation();
  }

  // Animation
  initAnimation() {

    // GSAP default easing
    gsap.defaults({
      ease: "power4.out",
      duration: 1.2
    });

    gsap.set(this.$images, {
      opacity: 0,
      y: "+=16"
    });

    ScrollTrigger.batch(this.$images, {
      // markers: true,
      start: "top 85%",
      once: true,
      onEnter: batch => gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: 0.3,
      }),
    })
  }
}
