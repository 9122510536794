export default class GalleryFilter {

  constructor(el) {
    this.$el = el;
    this.allFilterButton = this.$el.querySelector('.gallery-section__filter-button[data-filter="all"]');
    this.filterButtons = gsap.utils.toArray('.gallery-section__filter-button');
    this.activeFilterbutton = this.filterButtons.filter(filterButton => filterButton.classList.contains('active'))[0];
    this.galleryItems = gsap.utils.toArray('.gallery-card');
    this.initFilter();
  }


  // FLIP FILTER
  initFilter() {


    const updateFilters = () => {

      let that = this;

      const
        // Record initial state
        state = Flip.getState(this.galleryItems),

        // Get all filters
        filters = this.filterButtons.filter(function (filterButton) {
          return filterButton.classList.contains('active');
        }).map(function (filterButton) {
          return filterButton.dataset.filter;
        }),

        // Matching Items
        matches = [];

      // Fill in matches
      filters.forEach(function (filter) {
        console.log(filter);
        if (filter == 'all') {
          that.galleryItems.forEach(function (item) {
            matches.push(item);
          });
        } else {
          that.galleryItems.forEach(function (item) {
            if (item.dataset.type == filter) {
              matches.push(item);
            }
          });
        }
      });

      // adjust the display property of each item ("none" for filtered ones, "inline-flex" for matching ones)
      this.galleryItems.forEach(item => item.style.display = matches.indexOf(item) === -1 ? "none" : "inline-flex");

      // GSAP default easing
      gsap.defaults({
        ease: "power4.inOut",
        duration: 0.9
      });

      // animate from the previous state
      Flip.from(state, {
        // duration: 1.2,
        scale: false,
        absolute: false,
        // ease: "power4.inOut",
        onEnter: elements => gsap.fromTo(elements, {
          opacity: 0,
          scale: 0.95
        }, {
          opacity: 1,
          scale: 1,
          // duration: 1.2
        }),
        onLeave: elements => gsap.to(elements, {
          opacity: 0,
          scale: 0.95,
          // duration: 1.2
        })
      });

    }


    // Filter gallery items based on the data-type attribute
    this.filterButtons.forEach(btn => btn.addEventListener('click', () => {

      // Set active filter button
      this.activeFilterbutton.classList.remove('active');
      btn.classList.add('active');
      this.activeFilterbutton = btn;

      // Update Filter
      updateFilters();

    }));

  }
}
